import { Injectable } from "@angular/core"
import { ApplicationSetting, CardRetrievalRequest, concatCurrentPath, DayPlanRequest, DayPlanResponse, FrontendCard, GroupTemplate, PopApplication, StudyLibraryRequest, StudyLibraryResponse, StudySetting, User } from "../models"
import { BackendConfigService } from "@nx-superprep/backend/config"
import { UserService } from "./user.service"

import { Observable, distinctUntilChanged, of, switchMap } from "rxjs"
import { Functions, httpsCallableData } from "@angular/fire/functions"
import { EventParams, getAnalytics, logEvent, setUserProperties } from "@angular/fire/analytics"

import { log } from "@nx-superprep/utils"
import { isEqual } from "lodash-es"
import { environment } from "@nx-superprep/environment"

@Injectable({ providedIn: 'root' })
export abstract class LongtailService {

  protected studyGetLibrary: (data: StudyLibraryRequest) => Observable<StudyLibraryResponse>
  protected studyGetDayPlan: (data: DayPlanRequest) => Observable<DayPlanResponse>
  protected studyGetCards: (data: CardRetrievalRequest ) => Observable<FrontendCard[]>

  protected analytics = getAnalytics()

  private get analyticsInstance() {
    return this.analytics || getAnalytics()
  }

  constructor(
    protected backendService: BackendConfigService,
    protected userService: UserService,
    protected functions: Functions,
  ) {
    this.studyGetLibrary = httpsCallableData(functions, 'study_get_library_v1')
    this.studyGetDayPlan = httpsCallableData(functions, 'study_get_day_plan_v1')
    this.studyGetCards = httpsCallableData(functions, 'study_get_cards_v1')

    userService.userData.pipe(switchMap((user) => this.onUserChange(user))).subscribe()
  }

  abstract clear(): void

  onUserChange(user: User | undefined): Observable<User | undefined> {
    if (this.clear) { this.clear() }
    setUserProperties(this.analyticsInstance, {app_name: environment.config.app_name, app_version:environment.config.version})
    return of(user as User | undefined)
  }

  lt_application = ['Staging', 'Production'].includes(this.backendService.firebase.environment??'') ? PopApplication.LT_PROD_WEB : PopApplication.LT_DEV_WEB

  get uid() {
    return this.userService.uid
  }

  // setting

  getAppSetting(uid = this.uid) {
    if (!uid) { log.debug(`no active user`); return of(new ApplicationSetting('', new StudySetting({}))) }
    return this.userService.getAppSetting(uid).pipe(distinctUntilChanged(isEqual))
  }

  setAppSetting(value: ApplicationSetting, uid = this.uid) {
    if (!uid) { return Promise.reject('no active user') }
    return this.userService.setAppSetting(value, uid)
  }

  unsubscribe(): void {
    this.logUnsubscribeEvent()
  }

  private _logEvent(name: string, params: EventParams = {}) {
    logEvent(this.analyticsInstance, name, {app_name:environment.config.name, app_version:environment.config.version, ...params})
  }

  logUnsubscribeEvent(): void {
    const event = { }
    log.debug(`unsubscribe ${this.uid}`)
  }

  logSettingEvent(lesson_id: string|undefined, change: any = {}): void {
    if (Object.keys(change).length === 0) { return }
    const key = Object.keys(change)[0]
    const event = {
      lesson_id: lesson_id || 'N/A',
      setting_option_change: change[key]
    }
    log.debug(`setting_change_${key}`, event)
    //this._logEvent(`setting_change_${key}`, event)
  }

  logPerformanceFilterEvent(filter: GroupTemplate, state: any) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this._logEvent('select_content', event)
  }

  logPerformanceEvent(value: string|undefined) {
    if (!value) { return }
    const event = {
      content_type: 'performance',
      item_id: value,
      location: `/performance/${value}`
    }
    this._logEvent('select_item', event)
  }

  logLibraryFilterEvent(filter: GroupTemplate, state: any) {
    const event = {
      content_type: filter.title,
      item_id: filter.id,
      location: concatCurrentPath(state)
    }
    this._logEvent('select_content', event)
  }

  logLibraryEvent(value: any) {
    const section: string = value.selection.section?.title ?? value.selection.title ?? 'library'
    const selection: string = value.selection.item?.id ?? value.selection.item?.itemId
    const detail: string = value.selection.detail?.id
    const _itemId = detail ?? selection

    const event = _itemId ? {
      item_list_name: section,
      item_list_id: selection,
      items: [{item_id: _itemId, item_name: _itemId}],
      location: concatCurrentPath(value)
    } : {
      content_type: section ? section.toLowerCase() : 'library',
      item_id: section,
      location: concatCurrentPath(value)
    }

    if (event.content_type === 'library') { return }
    this._logEvent(_itemId ? 'select_item' : 'select_content', event)
  }

  logAppUpdate() {
    this._logEvent('app_update')
  }

  logAppOpen(component: string) {
    this._logEvent('app_open', {component})
  }

  logPageView(title: string, location?: string, path?: string) {
    this._logEvent('page_view', {page_title: title, page_location: location, page_path: path})
  }

  logSearch(keyword: string) {
    this._logEvent('search', {search_term: keyword})
  }

  logSelectContent(event: EventParams): void {
    this._logEvent('select_content', event)
  }

  logSelectItem(event: EventParams): void {
    this._logEvent('select_item', event)
  }

  logDailyPlanStart(event: EventParams) {
    this._logEvent('daily_lesson_plan_start', event)
  }

  logDailyPlanCompleted(event: EventParams) {
    this._logEvent('daily_lesson_plan_completed', event)
  }

  logDailyLessonStart(event: EventParams) {
    this._logEvent('daily_lesson_start', event)
  }

  logDailyLessonCompleted(event: EventParams) {
    this._logEvent('daily_lesson_completed', event)
  }

  logSessionStart(event: EventParams) {
    this._logEvent('study_session_start', event)
  }

  logSessionCompleted(event: EventParams) {
    this._logEvent('study_session_completed', event)
  }

  logLessonAdded(event: EventParams) {
    this._logEvent('lesson_added', event)
  }

  logLessonRemoved(event: EventParams) {
    this._logEvent('lesson_removed', event)
  }

  logLessonTryout(event: EventParams) {
    this._logEvent('lesson_try_out', event)
  }

  logLessonStart(event: EventParams) {
    this._logEvent('lesson_start', event)
  }

  logLessonCompleted(event: EventParams) {
    this._logEvent('lesson_completed', event)
  }

  logAccountDeletion(event: EventParams) {
    this._logEvent('account_deletion', event)
  }

}
